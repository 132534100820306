@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  -webkit-overflow-scrolling: touch;
}

.turbo-progress-bar {
  height: 3px;
  background-color: #e8c668;
}

.flex-stretch, #header nav, #header nav .logo, .nav-breakdown nav ul:not(.dropdown-menu) li, #page-heading nav ul:not(.dropdown-menu) li, #header nav ul:not(.dropdown-menu) li, #utility-nav nav ul:not(.dropdown-menu) li, .nav-breakdown nav ul:not(.dropdown-menu), #page-heading nav ul:not(.dropdown-menu), #header nav ul:not(.dropdown-menu), #utility-nav nav ul:not(.dropdown-menu), .nav-breakdown nav, #page-heading nav, #utility-nav nav, .nav-breakdown, #page-heading, #header, #utility-nav {
  display: flex;
  align-items: stretch;
}

.nav-breakdown nav ul:not(.dropdown-menu), #page-heading nav ul:not(.dropdown-menu), #header nav ul:not(.dropdown-menu), #utility-nav nav ul:not(.dropdown-menu) {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav-breakdown nav ul:not(.dropdown-menu) li a, #page-heading nav ul:not(.dropdown-menu) li a, #header nav ul:not(.dropdown-menu) li a, #utility-nav nav ul:not(.dropdown-menu) li a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-sep {
  width: 1px;
  background: #cccccc;
}

img {
  max-width: 100%;
}

hr {
  border-color: #cccccc;
  opacity: 1;
}
hr.thick {
  border-width: 3px;
}

.cursor-pointer {
  cursor: pointer;
}

.app-container, #page, .overlay-scrollable, .overlay-actions {
  padding: 2rem;
}
@media screen and (max-width: 991px) {
  .app-container, #page, .overlay-scrollable, .overlay-actions {
    padding: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .app-container, #page, .overlay-scrollable, .overlay-actions {
    padding: 1rem;
  }
}

.app-container-x, #page-heading nav, #header, #utility-nav {
  padding-left: 2rem;
  padding-right: 2rem;
}
@media screen and (max-width: 991px) {
  .app-container-x, #page-heading nav, #header, #utility-nav {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .app-container-x, #page-heading nav, #header, #utility-nav {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.app-container-y, .profile .business-name {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media screen and (max-width: 991px) {
  .app-container-y, .profile .business-name {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .app-container-y, .profile .business-name {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.w-400 {
  width: 4rem;
}

.w-1100 {
  width: 11rem;
}

a.btn-circle:hover {
  color: rgb(40, 42, 54) !important;
}

.text-black {
  color: #000000 !important;
}

.bg-black {
  background-color: #000000 !important;
}

.border-color-black {
  border-color: #000000 !important;
}

.text-table-border {
  color: #cccccc !important;
}

.bg-table-border {
  background-color: #cccccc !important;
}

.border-color-table-border {
  border-color: #cccccc !important;
}

.text-super-light {
  color: #f7f7f7 !important;
}

.bg-super-light {
  background-color: #f7f7f7 !important;
}

.border-color-super-light {
  border-color: #f7f7f7 !important;
}

.text-heading {
  color: #1d3461 !important;
}

.bg-heading {
  background-color: #1d3461 !important;
}

.border-color-heading {
  border-color: #1d3461 !important;
}

.text-white {
  color: #ffffff !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.border-color-white {
  border-color: #ffffff !important;
}

.text-link {
  color: #4575d1 !important;
}

.bg-link {
  background-color: #4575d1 !important;
}

.border-color-link {
  border-color: #4575d1 !important;
}

.text-link-danger {
  color: #c33333 !important;
}

.bg-link-danger {
  background-color: #c33333 !important;
}

.border-color-link-danger {
  border-color: #c33333 !important;
}

.text-highlight {
  color: #e8c668 !important;
}

.bg-highlight {
  background-color: #e8c668 !important;
}

.border-color-highlight {
  border-color: #e8c668 !important;
}

.text-bg-info {
  color: #ebf1ff !important;
}

.bg-bg-info {
  background-color: #ebf1ff !important;
}

.border-color-bg-info {
  border-color: #ebf1ff !important;
}

.text-bg-warning {
  color: #f9f0d8 !important;
}

.bg-bg-warning {
  background-color: #f9f0d8 !important;
}

.border-color-bg-warning {
  border-color: #f9f0d8 !important;
}

.text-bg-danger {
  color: #fbe4e4 !important;
}

.bg-bg-danger {
  background-color: #fbe4e4 !important;
}

.border-color-bg-danger {
  border-color: #fbe4e4 !important;
}

.text-bg-success {
  color: #def9d8 !important;
}

.bg-bg-success {
  background-color: #def9d8 !important;
}

.border-color-bg-success {
  border-color: #def9d8 !important;
}

.text-text-success {
  color: #384e33 !important;
}

.bg-text-success {
  background-color: #384e33 !important;
}

.border-color-text-success {
  border-color: #384e33 !important;
}

.text-text-warning {
  color: #4e462e !important;
}

.bg-text-warning {
  background-color: #4e462e !important;
}

.border-color-text-warning {
  border-color: #4e462e !important;
}

.text-text-danger {
  color: #5c3f3f !important;
}

.bg-text-danger {
  background-color: #5c3f3f !important;
}

.border-color-text-danger {
  border-color: #5c3f3f !important;
}

.text-text-info {
  color: #1d3461 !important;
}

.bg-text-info {
  background-color: #1d3461 !important;
}

.border-color-text-info {
  border-color: #1d3461 !important;
}

.text-border-info {
  color: #c4d1ef !important;
}

.bg-border-info {
  background-color: #c4d1ef !important;
}

.border-color-border-info {
  border-color: #c4d1ef !important;
}

.text-border-danger {
  color: #f4bebe !important;
}

.bg-border-danger {
  background-color: #f4bebe !important;
}

.border-color-border-danger {
  border-color: #f4bebe !important;
}

.text-border-warning {
  color: #f1dca4 !important;
}

.bg-border-warning {
  background-color: #f1dca4 !important;
}

.border-color-border-warning {
  border-color: #f1dca4 !important;
}

.text-border-success {
  color: #a1e293 !important;
}

.bg-border-success {
  background-color: #a1e293 !important;
}

.border-color-border-success {
  border-color: #a1e293 !important;
}

.text-border-input {
  color: #777771 !important;
}

.bg-border-input {
  background-color: #777771 !important;
}

.border-color-border-input {
  border-color: #777771 !important;
}

.text-default {
  color: #dfe5f0 !important;
}

.bg-default {
  background-color: #dfe5f0 !important;
}

.border-color-default {
  border-color: #dfe5f0 !important;
}

.border-top-none, .border-top-0 {
  border-top: 0 !important;
}

.border-top-thick {
  border-top-width: 3px !important;
}

.border-top-black {
  border-top-color: #000000 !important;
}

.border-top-table-border {
  border-top-color: #cccccc !important;
}

.border-top-super-light {
  border-top-color: #f7f7f7 !important;
}

.border-top-heading {
  border-top-color: #1d3461 !important;
}

.border-top-white {
  border-top-color: #ffffff !important;
}

.border-top-link {
  border-top-color: #4575d1 !important;
}

.border-top-link-danger {
  border-top-color: #c33333 !important;
}

.border-top-highlight {
  border-top-color: #e8c668 !important;
}

.border-top-bg-info {
  border-top-color: #ebf1ff !important;
}

.border-top-bg-warning {
  border-top-color: #f9f0d8 !important;
}

.border-top-bg-danger {
  border-top-color: #fbe4e4 !important;
}

.border-top-bg-success {
  border-top-color: #def9d8 !important;
}

.border-top-text-success {
  border-top-color: #384e33 !important;
}

.border-top-text-warning {
  border-top-color: #4e462e !important;
}

.border-top-text-danger {
  border-top-color: #5c3f3f !important;
}

.border-top-text-info {
  border-top-color: #1d3461 !important;
}

.border-top-border-info {
  border-top-color: #c4d1ef !important;
}

.border-top-border-danger {
  border-top-color: #f4bebe !important;
}

.border-top-border-warning {
  border-top-color: #f1dca4 !important;
}

.border-top-border-success {
  border-top-color: #a1e293 !important;
}

.border-top-border-input {
  border-top-color: #777771 !important;
}

.border-top-default {
  border-top-color: #dfe5f0 !important;
}

.border-right-none, .border-right-0 {
  border-right: 0 !important;
}

.border-right-thick {
  border-right-width: 3px !important;
}

.border-right-black {
  border-right-color: #000000 !important;
}

.border-right-table-border {
  border-right-color: #cccccc !important;
}

.border-right-super-light {
  border-right-color: #f7f7f7 !important;
}

.border-right-heading {
  border-right-color: #1d3461 !important;
}

.border-right-white {
  border-right-color: #ffffff !important;
}

.border-right-link {
  border-right-color: #4575d1 !important;
}

.border-right-link-danger {
  border-right-color: #c33333 !important;
}

.border-right-highlight {
  border-right-color: #e8c668 !important;
}

.border-right-bg-info {
  border-right-color: #ebf1ff !important;
}

.border-right-bg-warning {
  border-right-color: #f9f0d8 !important;
}

.border-right-bg-danger {
  border-right-color: #fbe4e4 !important;
}

.border-right-bg-success {
  border-right-color: #def9d8 !important;
}

.border-right-text-success {
  border-right-color: #384e33 !important;
}

.border-right-text-warning {
  border-right-color: #4e462e !important;
}

.border-right-text-danger {
  border-right-color: #5c3f3f !important;
}

.border-right-text-info {
  border-right-color: #1d3461 !important;
}

.border-right-border-info {
  border-right-color: #c4d1ef !important;
}

.border-right-border-danger {
  border-right-color: #f4bebe !important;
}

.border-right-border-warning {
  border-right-color: #f1dca4 !important;
}

.border-right-border-success {
  border-right-color: #a1e293 !important;
}

.border-right-border-input {
  border-right-color: #777771 !important;
}

.border-right-default {
  border-right-color: #dfe5f0 !important;
}

.border-bottom-none, .border-bottom-0 {
  border-bottom: 0 !important;
}

.border-bottom-thick {
  border-bottom-width: 3px !important;
}

.border-bottom-black {
  border-bottom-color: #000000 !important;
}

.border-bottom-table-border {
  border-bottom-color: #cccccc !important;
}

.border-bottom-super-light {
  border-bottom-color: #f7f7f7 !important;
}

.border-bottom-heading {
  border-bottom-color: #1d3461 !important;
}

.border-bottom-white {
  border-bottom-color: #ffffff !important;
}

.border-bottom-link {
  border-bottom-color: #4575d1 !important;
}

.border-bottom-link-danger {
  border-bottom-color: #c33333 !important;
}

.border-bottom-highlight {
  border-bottom-color: #e8c668 !important;
}

.border-bottom-bg-info {
  border-bottom-color: #ebf1ff !important;
}

.border-bottom-bg-warning {
  border-bottom-color: #f9f0d8 !important;
}

.border-bottom-bg-danger {
  border-bottom-color: #fbe4e4 !important;
}

.border-bottom-bg-success {
  border-bottom-color: #def9d8 !important;
}

.border-bottom-text-success {
  border-bottom-color: #384e33 !important;
}

.border-bottom-text-warning {
  border-bottom-color: #4e462e !important;
}

.border-bottom-text-danger {
  border-bottom-color: #5c3f3f !important;
}

.border-bottom-text-info {
  border-bottom-color: #1d3461 !important;
}

.border-bottom-border-info {
  border-bottom-color: #c4d1ef !important;
}

.border-bottom-border-danger {
  border-bottom-color: #f4bebe !important;
}

.border-bottom-border-warning {
  border-bottom-color: #f1dca4 !important;
}

.border-bottom-border-success {
  border-bottom-color: #a1e293 !important;
}

.border-bottom-border-input {
  border-bottom-color: #777771 !important;
}

.border-bottom-default {
  border-bottom-color: #dfe5f0 !important;
}

.border-left-none, .border-left-0 {
  border-left: 0 !important;
}

.border-left-thick {
  border-left-width: 3px !important;
}

.border-left-black {
  border-left-color: #000000 !important;
}

.border-left-table-border {
  border-left-color: #cccccc !important;
}

.border-left-super-light {
  border-left-color: #f7f7f7 !important;
}

.border-left-heading {
  border-left-color: #1d3461 !important;
}

.border-left-white {
  border-left-color: #ffffff !important;
}

.border-left-link {
  border-left-color: #4575d1 !important;
}

.border-left-link-danger {
  border-left-color: #c33333 !important;
}

.border-left-highlight {
  border-left-color: #e8c668 !important;
}

.border-left-bg-info {
  border-left-color: #ebf1ff !important;
}

.border-left-bg-warning {
  border-left-color: #f9f0d8 !important;
}

.border-left-bg-danger {
  border-left-color: #fbe4e4 !important;
}

.border-left-bg-success {
  border-left-color: #def9d8 !important;
}

.border-left-text-success {
  border-left-color: #384e33 !important;
}

.border-left-text-warning {
  border-left-color: #4e462e !important;
}

.border-left-text-danger {
  border-left-color: #5c3f3f !important;
}

.border-left-text-info {
  border-left-color: #1d3461 !important;
}

.border-left-border-info {
  border-left-color: #c4d1ef !important;
}

.border-left-border-danger {
  border-left-color: #f4bebe !important;
}

.border-left-border-warning {
  border-left-color: #f1dca4 !important;
}

.border-left-border-success {
  border-left-color: #a1e293 !important;
}

.border-left-border-input {
  border-left-color: #777771 !important;
}

.border-left-default {
  border-left-color: #dfe5f0 !important;
}

.border-thickness-1 {
  border-width: 1px !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.border-thickness-2 {
  border-width: 2px !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.border-thickness-3 {
  border-width: 3px !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.border-thickness-4 {
  border-width: 4px !important;
}

.z-index-4 {
  z-index: 4 !important;
}

.border-thickness-5 {
  border-width: 5px !important;
}

.z-index-5 {
  z-index: 5 !important;
}

.border-thickness-6 {
  border-width: 6px !important;
}

.z-index-6 {
  z-index: 6 !important;
}

.border-thickness-7 {
  border-width: 7px !important;
}

.z-index-7 {
  z-index: 7 !important;
}

.border-thickness-8 {
  border-width: 8px !important;
}

.z-index-8 {
  z-index: 8 !important;
}

.border-thickness-9 {
  border-width: 9px !important;
}

.z-index-9 {
  z-index: 9 !important;
}

.border-thickness-10 {
  border-width: 10px !important;
}

.z-index-10 {
  z-index: 10 !important;
}

html {
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: "Poppins", serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

#page p, #page li {
  font-size: 0.9rem;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #1d3461;
}

h1 {
  font-weight: 700;
}

h2, h3 {
  font-weight: 500;
}

h1, .h1 {
  font-size: 2rem;
}

h2, .h2 {
  font-size: 1.5rem;
}

h3, .h3 {
  font-size: 1.25rem;
  font-weight: 600;
}

h4, .h4 {
  font-size: 1.125em;
}

h5, .h5 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #000000;
}

h6 {
  font-size: 0.875rem;
  font-weight: 400;
  color: #000000;
  font-style: italic;
}

dl {
  margin-top: 1rem;
}
dl dt {
  font-size: 0.75rem;
  font-weight: 600;
  color: #777771;
}
dl dd {
  font-size: 0.875rem;
}

.mid-label {
  font-weight: 600;
  color: #777771;
  font-size: 0.75rem;
}

.btn {
  font-weight: 500;
  border-radius: 0.625rem;
  min-height: 2.625rem;
  display: inline-flex;
  line-height: 1.1;
  align-items: center;
  justify-content: center;
  gap: 0.75em;
}
.btn-primary {
  background: #e8c668;
  border-color: #e8c668;
  color: #000000;
}
.btn-primary:hover, .btn-primary:focus {
  border-color: transparent;
  color: #000000;
  background: rgb(221.8879310345, 172.9396551724, 37.6120689655);
}
.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 198, 104, 0.5);
}
.btn-link {
  color: #4575d1;
}
.btn-link:hover, .btn-link:focus {
  background: #ebf1ff;
}
.btn-link-danger {
  color: #c33333;
}
.btn-link-danger:hover, .btn-link-danger:focus {
  color: rgb(134.3597560976, 35.1402439024, 35.1402439024);
  background: #fbe4e4;
}
.btn-link i:before, .btn-link i {
  display: inline-block;
  text-decoration: none;
}
.btn-danger {
  background: #f4bebe;
  border-color: transparent;
  color: #5c3f3f;
}
.btn-danger:hover, .btn-danger:focus {
  border-color: transparent;
  color: #5c3f3f;
  background: rgb(238.8328947368, 159.4671052632, 159.4671052632);
}
.btn-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(244, 190, 190, 0.75);
}
.btn-warning {
  background: #f1dca4;
  border-color: #f1dca4;
  color: #4e462e;
}
.btn-warning:hover, .btn-warning:focus {
  border-color: transparent;
  color: #4e462e;
  background: rgb(247.8, 237, 208.2);
}
.btn-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 220, 164, 0.75);
}
.btn-success {
  background: #a1e293;
  border-color: #a1e293;
  color: #384e33;
}
.btn-success:hover, .btn-success:focus {
  border-color: transparent;
  color: #384e33;
  background: rgb(126.0072992701, 215.204379562, 106.795620438);
}
.btn-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(161, 226, 147, 0.75);
}

#error_explanation {
  background-color: #f4bebe;
  border-color: #f4bebe;
  border-radius: 0.625rem;
  font-size: 0.8rem;
}

.form-group label {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.25em;
}
.form-group label .required {
  color: #c33333;
}
.form-group .form-control,
.form-group .form-select {
  min-height: 2.625rem;
  border-color: #777771;
  border-radius: 0.625rem;
}
.form-group .form-control[aria-invalid],
.form-group .form-select[aria-invalid],
.form-group .form-check[aria-invalid],
.form-group .form-file[aria-invalid],
.form-group .form-multi-select[aria-invalid] {
  border-color: #f4bebe;
}
.form-group .form-control[aria-invalid] + em,
.form-group .form-select[aria-invalid] + em,
.form-group .form-check[aria-invalid] + em,
.form-group .form-file[aria-invalid] + em,
.form-group .form-multi-select[aria-invalid] + em {
  position: relative;
  font-size: 0.7rem;
  color: #c33333;
  top: -10px;
  left: 8px;
  display: block;
}
.form-group .form-file .input-group-text {
  border-color: #777771;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.form-group .form-file[aria-invalid] .input-group-text {
  border-color: #f4bebe;
  font-style: italic;
}
.form-group .form-file[aria-invalid] + em {
  top: -10px;
  display: block;
  margin-bottom: -15px;
}
.form-group .form-check[aria-invalid] + em {
  top: -20px;
  left: 35px;
}
.form-group .form-multi-select > div:first-of-type {
  border-color: #777771;
  border-radius: 0.625rem;
  min-height: 45px;
  cursor: pointer;
}
.form-group .form-multi-select div[class*=control] {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: initial;
}
.form-group .form-multi-select div[class*=control].focused {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  border-color: initial;
  outline: 0;
}
.form-group .form-multi-select div[class*=multiValue] {
  background-color: #1d3461;
}
.form-group .form-multi-select div[class*=multiValue] div[role=button]:hover {
  background-color: #c33333;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.form-group .form-multi-select div[class*=multiValue] > div {
  color: #ffffff;
}
.form-group .form-search {
  border-radius: 100rem;
  width: 19rem;
  padding-right: 2.75rem;
  background: url("icon-search.svg") calc(100% - 1rem) center no-repeat;
}
.form-group.filter-select {
  position: relative;
}
.form-group.filter-select label {
  white-space: nowrap;
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
}
.form-group.filter-select-show select {
  padding-left: 4.7rem;
}
.form-group.filter-select-sort select {
  padding-left: 5rem;
}
.form-group-multi-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.form-group .form-file input.form-control {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0 !important;
}
.form-group.col-4:first-of-type {
  padding-right: 0;
}
.form-group.col-4:last-of-type {
  padding-left: 0;
}
.form-group.col-6:last-of-type {
  padding-left: 0;
}

.form-check {
  padding-left: 0;
}
.form-check-input {
  display: none;
}
.form-check-input + .form-check-label {
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
}
.form-check-input + .form-check-label:before {
  display: block;
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid #777771;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0.25rem;
  cursor: pointer;
}
.form-check-input:checked + .form-check-label:before {
  background: #4575d1;
  border-color: #4575d1;
}
.form-check-input:checked + .form-check-label:after {
  display: block;
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  left: 0;
  top: 0;
  background: url(icon-checkbox-check.svg) center center no-repeat;
}
.form-check-input[type=radio] + .form-check-label:before {
  border-radius: 100rem;
}
.form-check-input[type=radio] + .form-check-label:after {
  background-image: url(icon-radio-dot.svg);
}

.fields-inline {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.fields-inline label {
  margin-bottom: 0;
}

.no-list-style {
  list-style: none;
  padding-left: 0;
}
.no-list-style li {
  margin-bottom: 0.5rem;
}
.no-list-style ul {
  list-style: none;
  padding-top: 0.5rem;
}

.badge {
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 0.875em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 0.9;
}
.badge.text-bg-secondary {
  border-color: #dfe5f0 !important;
  background: #dfe5f0 !important;
  color: #1d3461 !important;
}
.badge.text-bg-light {
  border-color: #dfe5f0 !important;
  background: #f7f7f7 !important;
  color: #000000 !important;
}
.badge.text-bg-success {
  border-color: #a1e293 !important;
  background: #def9d8 !important;
  color: #384e33 !important;
}
.badge.text-bg-warning {
  border-color: #f1dca4 !important;
  background: #f9f0d8 !important;
  color: #4e462e !important;
}
.badge.text-bg-danger {
  border-color: #f4bebe !important;
  background: #fbe4e4 !important;
  color: #5c3f3f !important;
}
.badge.text-bg-dark {
  border-color: #1d3461 !important;
  background: #1d3461 !important;
  color: #ffffff !important;
}
.badge.text-bg-info, .badge.text-bg-primary {
  border-color: #c4d1ef !important;
  background: #ebf1ff !important;
  color: #1d3461 !important;
}

.table {
  border-color: #cccccc;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: #f7f7f7 !important;
  box-shadow: none;
}
.table thead th {
  position: relative;
  padding-right: 2.5rem;
}
.table .btn-sort {
  color: inherit;
  background: none;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  padding: 0.5rem 0.75rem;
  margin-left: auto;
  position: absolute;
  right: 0.25rem;
  top: 0.55rem;
}
.table .btn-sort i {
  opacity: 0.2;
}
.table .btn-sort:hover {
  background: #f7f7f7;
  opacity: 1;
}
.table .btn-sort-active i {
  opacity: 1;
}
.table:not(.table-sm) td, .table:not(.table-sm) th {
  padding: 0.5rem 1rem;
  height: 3rem;
  vertical-align: middle;
}
.table:not(.table-sm) th {
  line-height: 1.2;
}
.table.table-sm td, .table.table-sm th {
  padding: 0.5rem;
  height: auto;
  line-height: 1;
}
.table td .approval-details {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}
.table-actions {
  display: flex;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 1rem;
}
.table-actions-buttons {
  margin-left: auto;
  display: flex;
  gap: 1rem;
}
@media screen and (max-width: 900px) {
  .table-actions-buttons {
    flex-direction: column-reverse;
  }
}
.table-actions fieldset#view-options {
  display: flex;
  align-items: center;
}
.table-actions fieldset#view-options .view-option-input {
  display: none;
}
.table-actions fieldset#view-options .view-option-label {
  border: 1px solid #777771;
  min-height: 2.625rem;
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
}
.table-actions fieldset#view-options .view-option-label-grid {
  border-radius: 0.625rem 0 0 0.625rem;
  border-right: none;
}
.table-actions fieldset#view-options .view-option-label-table {
  border-radius: 0 0.625rem 0.625rem 0;
  border-left: none;
}
.table-actions fieldset#view-options .view-option-label:hover {
  background: #ebf1ff;
  cursor: pointer;
}
.table-actions fieldset#view-options .view-option.current .view-option-label {
  background: #4575d1;
  border-color: #4575d1;
  color: #ffffff;
  cursor: default;
}
.table-actions fieldset#view-options legend {
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0.625rem 0 0;
}
.table-actions .search-group {
  display: flex;
  gap: 1rem;
}
@media screen and (max-width: 555px) {
  .table-actions {
    flex-direction: column;
    align-items: stretch;
  }
  .table-actions .search-group, .table-actions .search, .table-actions .form-group .form-search {
    width: 100%;
  }
  .table-actions-buttons {
    width: 100%;
  }
  .table-actions-buttons fieldset {
    flex-direction: row;
    justify-content: flex-end;
  }
  .table-actions-buttons fieldset legend {
    width: auto;
    flex: 0 1 auto;
  }
}
.table-pagination {
  display: flex;
  justify-content: center;
}

.card {
  position: relative;
}
.card .badge {
  position: absolute;
  left: 1rem;
  top: 1rem;
}
.card-img-top {
  margin: 1rem 1rem 0.5rem 1rem;
  display: block;
  height: 200px;
  object-fit: contain;
  width: calc(100% - 2rem);
  border-radius: 0;
}
.card-product dl {
  margin-top: 1rem;
}
.card-product dl dt {
  font-size: 0.625rem;
}
.card-product dl dd {
  font-size: 0.875rem;
}
.card-actions {
  display: flex;
  justify-content: stretch;
  gap: 0.75rem;
}
.card-actions .btn {
  flex: 1 1 auto;
}
@media screen and (max-width: 290px) {
  .card-actions {
    flex-direction: column-reverse;
  }
}

.pagination {
  display: flex;
  gap: 0.25rem;
}
.pagination li a {
  color: #000000;
  border: none;
  padding: 0.4rem 1rem;
  border-radius: 100rem !important;
  border: 1px solid transparent;
}
.pagination li a:hover {
  background: #ebf1ff;
  border: 1px solid #c4d1ef;
  color: #000000;
}
.pagination li.disabled {
  opacity: 0.5;
}
.pagination li.disabled a {
  background: none;
  border-color: transparent;
}
.pagination li.active a {
  border-radius: 100rem;
  background: #ebf1ff;
  border: 1px solid #c4d1ef;
  color: #000000;
}

.alert h4 {
  font-weight: 400;
}
.alert p {
  margin: 0.75rem 0;
}
.alert *:last-child {
  margin-bottom: 0;
}
.alert-primary {
  border-color: transparent;
  background: #ebf1ff;
  color: #1d3461;
}
.alert-secondary {
  border-color: transparent;
  background: #dfe5f0;
  color: #000000;
}
.alert-success {
  border-color: transparent;
  background: #def9d8;
  color: #384e33;
}
.alert-danger {
  border-color: transparent;
  background: #fbe4e4;
  color: #5c3f3f;
}
.alert-warning {
  border-color: transparent;
  background: #f9f0d8;
  color: #4e462e;
}
.alert-info {
  border-color: transparent;
  background: #ebf1ff;
  color: #1d3461;
}
.alert-light {
  border-color: #dfe5f0;
  background: #f7f7f7;
  color: #000000;
}
.alert-dark {
  border-color: transparent;
  color: #ffffff;
  background: #1d3461;
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75rem;
  border: none;
}

.offcanvas-backdrop {
  cursor: pointer;
}

.notification-read {
  background: #cccccc !important;
  opacity: 0.4;
}
.notification-read:hover {
  background: #cccccc !important;
}

.logo-compact {
  width: 80px;
}

.flash.alert {
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  margin-bottom: 0;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: between;
}

.overlay-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(6px);
  z-index: 9998;
}
.overlay-content {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
  width: 880px;
  max-width: calc(100% - 2rem);
  background: #ffffff;
  display: flex;
  flex-direction: column;
}
.overlay-scrollable {
  overflow: auto;
  flex: 1 1 auto;
}
.overlay-scrollable .overlay-title {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 0.35em;
  margin-bottom: 1rem;
}
.overlay-actions {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-top: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}
.overlay-actions-buttons {
  display: flex;
  width: 100%;
}
.overlay-actions-confirmations {
  margin-left: auto;
  display: flex;
  gap: 0.75rem;
}
.overlay-actions-additional-fields {
  margin-bottom: 1.5rem;
}

#developer-tools {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 43px;
  z-index: 2;
  border-top: 8px solid #44475A;
  border-top-left-radius: 20px;
  background-color: rgba(40, 42, 54, 0.85);
  color: #F8F8F2;
  font-size: 0.9rem;
  padding: 5px 0 5px 13px;
  transition: all 100ms ease-in-out;
}
#developer-tools #developer-tools--button,
#developer-tools #developer-tools--close {
  cursor: pointer;
}
#developer-tools #developer-tools--button {
  font-size: 1.1rem;
}
#developer-tools--content {
  display: none;
}
#developer-tools--content h4 {
  color: #FF79C6;
}
#developer-tools--content h4 i {
  color: #F8F8F2;
  margin-right: 8px;
}
#developer-tools--content a {
  color: #8BE9FD;
}
#developer-tools.open {
  border-top-right-radius: 20px;
  padding: 15px 30px 10px 30px;
  width: 100%;
  right: 0;
}
#developer-tools.open #developer-tools--button {
  display: none;
}
#developer-tools.open #developer-tools--content {
  display: block;
}
#developer-tools.open #developer-tools--content #developer-tools--close {
  font-size: 1.4rem;
  margin: -5px -5px 0 0;
}

#utility-nav {
  display: flex;
  background: rgb(31, 59, 183);
  background: linear-gradient(0deg, rgb(31, 59, 183) 0%, rgb(67, 50, 155) 100%);
  height: 2.75rem;
  justify-content: flex-end;
  font-size: 0.875rem;
}
#utility-nav nav ul li a:not(.dropdown-item) {
  position: relative;
  color: #ffffff;
  padding: 0 0.75rem;
  text-decoration: none;
}
#utility-nav nav ul li a:not(.dropdown-item)::after {
  position: absolute;
  display: block;
  bottom: 10px;
  left: 10%;
  right: 10%;
  content: "";
  transition: border-bottom 100ms ease-in-out;
}
#utility-nav nav ul li a:not(.dropdown-item):hover, #utility-nav nav ul li a:not(.dropdown-item):active {
  text-decoration: none;
}
#utility-nav nav ul li a:not(.dropdown-item):hover::after, #utility-nav nav ul li a:not(.dropdown-item):active::after {
  border-bottom: 2px solid #ffffff;
}
#utility-nav nav ul li:last-child:before {
  content: "";
  display: block;
  border-left: 1px solid #ffffff;
  margin: 0.75rem 0.5rem;
}
#utility-nav nav ul li.active a:not(.dropdown-item)::after {
  position: absolute;
  display: block;
  bottom: 10px;
  left: 10%;
  right: 10%;
  content: "";
  border-bottom: 2px solid #e8c668;
}
@media screen and (min-width: 768px) {
  #utility-nav nav ul li.user i {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  #utility-nav nav ul li a {
    font-size: 0.75rem;
    padding: 0 0.5rem;
  }
  #utility-nav nav ul li.user a {
    font-size: 1rem;
  }
  #utility-nav nav ul li.user span {
    display: none;
  }
}

#header {
  height: 6.25rem;
  background: #ffffff;
}
#header nav {
  width: 100%;
  justify-content: space-between;
}
#header nav .logo img {
  width: 6.75rem;
  object-fit: contain;
}
#header nav .nav-cluster {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 951px) {
  #header nav .nav-cluster .mobile-nav-toggle {
    display: none;
  }
}
#header nav .nav-cluster .mobile-nav-toggle-close {
  display: none;
}
#header nav .nav-cluster ul {
  align-items: center;
}
#header nav .nav-cluster ul li a {
  color: #000000;
  font-weight: 500;
  text-decoration: none;
  padding: 0 0.75rem;
  height: 2.5rem;
  transition: 0.2s ease box-shadow;
}
#header nav .nav-cluster ul li a:hover {
  box-shadow: inset 0 -3px 0 rgba(232, 198, 104, 0.5);
}
#header nav .nav-cluster ul li.active a {
  box-shadow: inset 0 -3px 0 #e8c668;
}
@media screen and (max-width: 1130px) {
  #header nav .nav-cluster ul li a {
    font-size: 0.875rem;
    padding: 0 0.5rem;
  }
}
@media screen and (max-width: 950px) {
  #header nav .nav-cluster .nav-container {
    transform: translateY(-100vh);
    transition: 0.75s ease transform, 0.75s ease box-shadow;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    overflow-y: auto;
    background: #ffffff;
    z-index: 9999;
  }
  #header nav .nav-cluster .nav-container ul {
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
  }
  #header nav .nav-cluster .nav-container ul:before {
    content: "Menu";
    font-size: 1.5rem;
    margin-bottom: 0.75em;
  }
  #header nav .nav-cluster .nav-container ul li a {
    font-size: 1rem;
    height: auto;
    padding: 0.75rem;
  }
  #header nav .nav-cluster .nav-container .mobile-nav-toggle-close {
    display: inline-block;
    border-radius: 0.625rem;
    padding: 0.35rem 0.75rem;
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    z-index: 10000;
    background: none;
    border: none;
    font-size: 1.25rem;
  }
  #header nav .nav-cluster .nav-container.open {
    transform: translateY(0);
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.5);
  }
}

#page-heading {
  background: rgba(31, 59, 183, 0.66);
  background: linear-gradient(180deg, rgba(31, 59, 183, 0.66) 0%, rgba(67, 50, 155, 0.66) 100%);
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;
  flex-wrap: wrap;
}
#page-heading .page-heading-group {
  color: #ffffff;
  padding: 1rem 0 0.65rem 2rem;
}
@media screen and (max-width: 991px) {
  #page-heading .page-heading-group {
    padding: 1rem 0 0.65rem 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  #page-heading .page-heading-group {
    padding: 1rem 0 0.65rem 1rem;
  }
}
#page-heading .page-heading-group h1 {
  color: #ffffff;
}
#page-heading .page-heading-group .crumbs {
  font-size: 0.8rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
#page-heading .page-heading-group .crumbs a, #page-heading .page-heading-group .crumbs span {
  color: #ffffff;
  font-weight: 600;
}
#page-heading .page-heading-group .crumbs i {
  font-size: 0.875em;
}
#page-heading nav {
  max-width: 100%;
  overflow-x: auto;
}
#page-heading nav ul {
  display: flex;
  gap: 0.375rem;
  align-items: flex-end;
  align-items: stretch;
}
#page-heading nav ul li {
  padding-top: 0.5rem;
}
#page-heading nav ul li a {
  background: #ebf1ff;
  color: #000000;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 0.75rem 0.75rem 0 0;
  border-bottom: 1px solid #C4D1EF;
  line-height: 1;
  text-align: center;
  margin-top: 0.25rem;
  transition: 0.2s ease background-color;
}
#page-heading nav ul li a:hover {
  background-color: #ffffff;
}
#page-heading nav ul li.active {
  padding-top: 0;
  min-height: 3.5rem;
}
#page-heading nav ul li.active a {
  background: #ffffff;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.45);
  border-bottom-color: transparent;
}

#page .page-content-heading-container {
  border-bottom: 1px solid #cccccc;
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  flex-wrap: wrap;
}
#page .page-content-heading-container h2 {
  margin-bottom: 0;
}
#page .page-content-heading-container-actions {
  margin-left: auto;
}

.public-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("bg-login.jpg") no-repeat center/cover;
}
.public-container .public-card {
  background: #ffffff;
  width: 92vw;
  max-width: 750px;
  margin: 2rem;
  padding: 2rem;
  border-radius: 1.5rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);
}
.public-container .public-card.xs {
  max-width: 600px !important;
}
.public-container .public-card.sm {
  max-width: 750px !important;
}
.public-container .public-card.md {
  max-width: 850px !important;
}
.public-container .public-card.lg {
  max-width: 950px !important;
}
.public-container .public-card.xl {
  max-width: 1050px !important;
}
.public-container .public-card.xxl {
  max-width: 1150px !important;
}
.public-container .public-card.xxxl {
  max-width: 1250px !important;
}
.public-container .public-card.full {
  max-width: initial !important;
}
.public-container .public-card h1 {
  color: #1d3461;
}

#login .public-card {
  background: #ffffff;
  width: 764px;
  padding: 2rem;
  border-radius: 1.5rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);
}
#login .public-card h1 {
  color: #1d3461;
}
#login .login-logo {
  width: 138px;
  margin-bottom: 1.5rem;
}
#login .login-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-tiles {
  --gap: 1.5rem;
  --columns: 5;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  gap: var(--gap);
}
@media screen and (max-width: 1490px) {
  .product-tiles {
    --columns: 4;
  }
}
@media screen and (max-width: 1190px) {
  .product-tiles {
    --columns: 3;
  }
}
@media screen and (max-width: 900px) {
  .product-tiles {
    --columns: 2;
  }
}
@media screen and (max-width: 612px) {
  .product-tiles {
    --columns: 1;
  }
}
.product-tiles .card {
  box-sizing: border-box;
  width: calc(100% / var(--columns) - var(--gap) + var(--gap) / var(--columns));
}
.product-details dl ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.product-details-header {
  display: flex;
  align-items: flex-start;
}
.product-details-header-action {
  margin-left: auto;
}
@media screen and (max-width: 576px) {
  .product-details-header {
    flex-direction: column;
    align-items: stretch;
  }
  .product-details-header-action {
    margin-left: 0;
  }
  .product-details-header-action .btn {
    width: 100%;
  }
}

.profile h4 {
  font-weight: 600;
}
.profile b {
  font-weight: 600;
}
.profile .application-header {
  display: flex;
  border: 1px solid #cccccc;
  border-left: none;
  border-right: none;
  padding: 1rem 0;
  justify-content: space-between;
}
.profile .business-name {
  text-align: center;
}
.profile .business-name div {
  font-size: 1.75rem;
}
.profile table {
  width: 100%;
}
.profile table th {
  text-align: right;
  padding-right: 0.5rem;
}
.profile table th, .profile table td {
  vertical-align: top;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
@media screen and (max-width: 767px) {
  .profile table th, .profile table td {
    width: 50%;
  }
}
.profile table td {
  padding-left: 0.5rem;
}
@media screen and (max-width: 575px) {
  .profile table th, .profile table td {
    display: block;
    text-align: left;
  }
  .profile table td {
    margin-bottom: 0.5rem;
  }
}
.profile table ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
